<template>
  <div class="wrapper">
    <div class="searchbox">
      <el-form :inline="true">
        <el-form-item label="活动名称">
          <el-select
            v-model="search.activityid"
            filterable
            @change="chooseActivity"
            clearable
            placeholder="请选择项目"
          >
            <el-option
              v-for="item in activities"
              :key="item.actuuid"
              :label="item.actname"
              :value="item.actuuid"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="项目名称" v-if="search.activityid">
          <el-select
            v-model="search.itemid"
            @change="chooseItem"
            clearable
            placeholder="请选择项目"
          >
            <el-option
              v-for="item in items"
              :key="item.itemid"
              :label="item.title"
              :value="item.itemid"
            >
            </el-option>
          </el-select>
        </el-form-item>
         <el-form-item label="活动时间" v-if="search.itemid">
          <el-select
            v-model="search.iteminfoid"
            clearable
            placeholder="请选择活动时间"
          >
            <el-option
              v-for="item in itemInfos"
              :key="item.iteminfoid"
              :label="item.begintime + '~' + item.endtime"
              :value="item.iteminfoid"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearch">查询</el-button>
          <el-button type="success" v-if="multipleSelection.length>0" @click="batchCheck">批量审核</el-button>
        </el-form-item>
      </el-form>
    </div>

    <el-table :data="tableData" stripe @selection-change="handleSelectionChange">
      <el-table-column
        type="selection"
        width="55">
      </el-table-column>
      <el-table-column prop="actname" label="活动名称" width="160px" show-overflow-tooltip> </el-table-column>
      <el-table-column prop="title" label="项目名称" show-overflow-tooltip> </el-table-column>
      <el-table-column label="活动起止时间" width="300px">
        <template slot-scope="scope">
          <span>{{ scope.row.begintime }} ~ {{ scope.row.endtime }}</span>
        </template>
      </el-table-column>
      <el-table-column label="场地名称"  width="120px" show-overflow-tooltip>
        <template slot-scope="scope">
          <span v-if="scope.row.zzareaname">{{ scope.row.zzareaname}}</span>
          <span v-else>/</span>
        </template>
      </el-table-column>
      <el-table-column prop="nickname" label="昵称" show-overflow-tooltip> </el-table-column>
      <el-table-column
        prop="joincontents"
        label="报名字段项"
        show-overflow-tooltip
         width="160px"
      >
      </el-table-column>
      <el-table-column prop="jointime" width="160px" label="报名时间">
      </el-table-column>
      <el-table-column prop="paypoint" label="扣除积分"> </el-table-column>
      <el-table-column label="消费金额（元）" width="150px">
        <template slot-scope="scope">
          <span>{{ scope.row.payrmb ? scope.row.payrmb / 100 : "/" }}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="address" label="支付状态">
        <template slot-scope="scope">
          <span v-if="scope.row.paystate === '0'">已支付</span>
          <span v-else-if="scope.row.paystate === '1'">未支付</span>
          <span v-else>/</span>
        </template>
      </el-table-column> -->

      <el-table-column label="操作" fixed="right" width="120" align="center">
        <template slot-scope="scope">
          <el-button @click="doorCheck(scope.row)" type="success">审核</el-button>
        </template>
      </el-table-column>

    </el-table>
    <div class="paginationbox">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="fenye.pagesizes"
        :page-size="fenye.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="fenye.totalnum"
      >
      </el-pagination>
    </div>

    <!-- 活动审核 -->
    <el-dialog append-to-body title="审核" :close-on-click-modal='false' :visible.sync="checkDialogVisible"  width="500px" :before-close="checkhandleClose">
      <el-form :model="form" :rules="rules" ref="form" label-width="100px">
        <el-form-item label="审核结果" prop="ispass">
          <el-radio-group v-model="form.ispass">
            <el-radio label="1">通过</el-radio>
            <el-radio label="0">不通过</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="审核意见" prop="opinion" v-if="form.ispass === '0'">
          <el-input type="textarea" v-model="form.opinion"></el-input>
        </el-form-item>
        <el-form-item label-width="10px" style="margin-top: 40px; text-align: center;">
          <el-button type="primary" @click="submitForm('form')">提交审核</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  doorAuditList,
  doorAudit,
  activityItemQuery,
  itemInfoListQuery,
  getActivityListForDooraudit,
} from "@/api/acitvity/index.js";

export default {
  name: "",
  components: {},
  props: {
    actuuid: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      tableData: [],
      fenye: {
        totalnum: 0,
        pagesizes: [10, 20, 30, 40, 50],
        pagesize: 10,
      },
      currentPage: 1,
      activities:[],
      items: [],
      itemInfos: [],
      search: {
        activityid:'',
        itemid: "",
        iteminfoid: "",
      },
      //审核活动字段
      checkDialogVisible: false,
      form: {
        ispass: '',
        opinion: ''
      },
      checkActuuid:'',
      rules:{
        ispass: [
          { required: true, message: '请选择审核结果', trigger: 'change' }
        ],
        opinion: [
          { required: true, message: '请输入审核意见', trigger: 'change' }
        ]
      },
      multipleSelection:[]
    };
  },
  watch: {},
  created() {
    // this.getItems();
    this.getActivities();
    this.getList();
  },
  mounted() {},
  computed: {},
  methods: {
    getList() {
      doorAuditList({
        userid: this.GLOBAL.adminId(),
        pagesize: this.fenye.pagesize,
        pagenum: this.currentPage,
        actuuid: this.search.activityid,
        itemid: this.search.itemid,
        iteminfoid: this.search.iteminfoid
      }).then((res) => {
        if (res.result == 200) {
          let detail = res.detail;
          this.fenye.totalnum = detail.totalRow;
          this.tableData = detail.list;
        } else {
          this.$message.error(res.description);
        }
      });
    },
    //切换页面条数
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.fenye.pagesize = val;
      this.currentPage = 1;
      this.getList();
    },
    //切换分页
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getList();
    },
    //搜索
    onSearch() {
      this.currentPage = 1;
      this.getList();
    },
    //获取活动
    getActivities(){
      getActivityListForDooraudit({
        userid: this.GLOBAL.adminId(),
        pageSize: 1000,
        pageNum: 1,
      }).then((res) => {
        if (res.result == 200) {
          this.activities = res.detail;
        } else {
          this.$message.error(res.description);
        }
      });
    },
    //获取活动下的项目
    chooseActivity(activityid){
      this.search.itemid = "";
      this.search.iteminfoid = "";
      if (activityid) {
        activityItemQuery({
          userid: this.GLOBAL.adminId(),
          pageSize: 1000,
          pageNum: 1,
          actuuid: activityid,
        }).then((res) => {
          if (res.result == 200) {
           this.items = res.detail.list;
          } else {
            this.$message.error(res.description);
          }
        });
      }

    },
    //获取项目下的项目信息
    chooseItem(itemid) {
      this.search.iteminfoid = "";
      if (itemid) {
        itemInfoListQuery({
          userid: this.GLOBAL.adminId(),
          pageSize: 1000,
          pageNum: 1,
          itemid: itemid,
        }).then((res) => {
          if (res.result == 200) {
            this.itemInfos = res.detail.pageResult.list;
            console.log(this.itemInfos);
          } else {
            this.$message.error(res.description);
          }
        });
      }
    },
    //多选可批量审核
    handleSelectionChange(val) {
      this.multipleSelection = val;
      // console.log(this.multipleSelection);
    },
    //审核活动，打开弹框、获取主体信息
    doorCheck(row){
      // console.log(row)
      this.checkDialogVisible = true;
      this.checkActuuid = row.activityjoinid;
    },
    //批量审核
    batchCheck(){
      let activityjoinids = []
      this.multipleSelection.map(item=>{
        activityjoinids.push(item.activityjoinid)
      })
      this.checkActuuid = activityjoinids.join(',');
      this.checkDialogVisible = true;
    },
    //审核按钮
    submitForm(formName) {
      // console.log(this.checkActuuid,this.form.ispass,this.form.opinion)
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$confirm('是否确认提交审核?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            doorAudit({
              userid: this.GLOBAL.adminId(),
              activityjoinid: this.checkActuuid,
              ispass: this.form.ispass,
              opinion: this.form.opinion
            }).then(res=>{
              if(res.result == 200){
                this.$message.success('操作成功');
                this.resetForm('form');
                this.checkDialogVisible = false;
                this.checkActuuid = '';
                this.getList();
              } else {
                this.$message.error(res.description);
              }
            })
          }).catch(() => {
                  
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    //审核表单重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    //关闭审核表单
    checkhandleClose(done) {
      this.resetForm('form')
      done();
    },
  },
};
</script>

<style lang="scss" scoped>
.searchbox {
  margin: 0 10px;
}
.paginationbox {
  text-align: right;
  margin-top: 20px;
}
</style>